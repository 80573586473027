import { useMutation } from "@apollo/react-hooks";
import { Button, Col, Form, Input, notification, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import React, { FC, FormEventHandler, useCallback } from "react";
import routes from "routes";
import { ChangePasswordDto } from "types/graphql-global-types";
import { useErrorHandler, useNavigate } from "utils";

import CHANGE_PASSWORD from "./changePassword.graphql";
import {
  ChangePassword as ChangePasswordData,
  ChangePasswordVariables,
} from "./types/ChangePassword";

type Props = FormComponentProps<ChangePasswordDto>;

const ChangePassword: FC<Props> = ({
  form: { getFieldDecorator, validateFieldsAndScroll },
}) => {
  const [changePassword, { error }] = useMutation<
    ChangePasswordData,
    ChangePasswordVariables
  >(CHANGE_PASSWORD);

  useErrorHandler(error);

  const navigateToHome = useNavigate(routes.home);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    e => {
      if (e) {
        e.preventDefault();
      }

      validateFieldsAndScroll((validationErrors, values) => {
        (async () => {
          if (validationErrors) {
            return;
          }

          const { errors } = await changePassword({
            variables: {
              input: values,
            },
          });

          if (errors) {
            return;
          }

          notification.success({
            message: "Успех!",
            description: "Ваш пароль был успешно изменён",
          });

          navigateToHome();
        })();
      });
    },
    [changePassword, navigateToHome, validateFieldsAndScroll]
  );

  return (
    <Authorize>
      <MainLayout title="Смена пароля" onBack={navigateToHome}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col span={6}>
              <Form.Item label="Текущий пароль" hasFeedback={true}>
                {getFieldDecorator<ChangePasswordDto>("currentPassword", {
                  rules: [{ required: true }],
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Новый пароль" hasFeedback={true}>
                {getFieldDecorator<ChangePasswordDto>("newPassword", {
                  rules: [{ required: true }],
                })(<Input.Password />)}
              </Form.Item>
            </Col>
          </Row>
          <div className="form-actions">
            <Button
              className="mr-2"
              htmlType="submit"
              icon="save"
              size="large"
              type="primary"
            >
              Сохранить
            </Button>
            <Button
              icon="close"
              size="large"
              type="default"
              onClick={navigateToHome}
            >
              Отмена
            </Button>
          </div>
        </Form>
      </MainLayout>
    </Authorize>
  );
};

export default Form.create()(ChangePassword);
